import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Table } from "react-bootstrap";
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../App.css";

const MarkAttendance = ({ id, host}) => {
    const [emp, setEmp] = useState();
    const [currentTime, setCurrentTime] = useState(new Date());

    const [workDuration, setWorkDuration] = useState('00:00:00');

    const [present, setPresent] = useState(false)

    const [attendanceRecords, setAttendanceRecords] = useState({
        date: '',
        inTime: '00:00:00',
        outTime: '00:00:00',
        breakTime: '00:00:00',
        totalTime: '00:00:00',
        status: '',
        breakStartTime: '00:00:00'
    });

    const [isClockedIn, setIsClockedIn] = useState(false);
    const [isOnBreak, setIsOnBreak] = useState(false);

    const [showSummary, setShowSummary] = useState(false);

    useEffect(() => {
        try {
            const response = axios.get(`${host}/api/employee/${id}`)
            response.then((result) => {
                setEmp(result.data)
                if (result.data.attendanceInfo?.attendanceRecords[result.data.attendanceInfo?.attendanceRecords.length - 1]?.date === new Date().toISOString().split('T')[0]) {
                    if (result.data.attendanceInfo?.attendanceRecords[result.data.attendanceInfo?.attendanceRecords.length - 1]?.status != 'present' ) {
                        setAttendanceRecords(result.data?.attendanceInfo?.attendanceRecords[result.data?.attendanceInfo?.attendanceRecords.length - 1])
                        setIsClockedIn(true)
                        if (result.data.attendanceInfo?.attendanceRecords[result.data.attendanceInfo?.attendanceRecords.length - 1]?.breakStartTime != "") {
                            setIsOnBreak(true)
                        }
                        // console.log(result.data?.attendanceInfo?.attendanceRecords[result.data?.attendanceInfo?.attendanceRecords.length - 1])
                    } else {
                        setPresent(true)
                        setAttendanceRecords(result.data?.attendanceInfo?.attendanceRecords[result.data?.attendanceInfo?.attendanceRecords.length - 1])
                    }
                }
                if (attendanceRecords.inTime && !isOnBreak) {
                    setWorkDuration(/*getTimeDifference(attendanceRecords.breakTime, */getTimeDifference(attendanceRecords.inTime, convertTo24Hour(new Date().toLocaleTimeString()))/*)*/);
                    // console.log(getTimeDifference(attendanceRecords.breakTime,getTimeDifference(attendanceRecords.inTime,convertTo24Hour(new Date().toLocaleTimeString()))))
                }
            })
        } catch {
            // console.log('Error fetching Employee Data');
        }
    }, [currentTime, isClockedIn, isOnBreak, attendanceRecords.inTime, attendanceRecords.breakStartTime, attendanceRecords.breakTime, attendanceRecords.outTime, attendanceRecords.totalTime]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const getTimeDifference = (startTime1, endTime1) => {
        let startTime;
        let endTime;

        (!startTime1) ? startTime = '00:00:00' : startTime = startTime1;
        (!endTime1) ? endTime = '00:00:00' : endTime = endTime1;
        // Parse the start and end times
        const startParts = startTime.split(':');
        const endParts = endTime.split(':');

        const startDate = new Date();
        startDate.setHours(parseInt(startParts[0], 10));
        startDate.setMinutes(parseInt(startParts[1], 10));
        startDate.setSeconds(parseInt(startParts[2], 10));

        const endDate = new Date();
        endDate.setHours(parseInt(endParts[0], 10));
        endDate.setMinutes(parseInt(endParts[1], 10));
        endDate.setSeconds(parseInt(endParts[2], 10));

        // Calculate the difference in milliseconds
        let diff = endDate - startDate;

        // If the difference is negative, it means the end time is on the next day
        if (diff < 0) {
            endDate.setDate(endDate.getDate() + 1);
            diff = endDate - startDate;
        }

        // Convert back to hours, minutes, and seconds
        const diffHours = Math.floor(diff / (1000 * 60 * 60));
        const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const diffSeconds = Math.floor((diff % (1000 * 60)) / 1000);

        // Format the result as HH:mm:ss
        const formattedHours = String(diffHours).padStart(2, '0');
        const formattedMinutes = String(diffMinutes).padStart(2, '0');
        const formattedSeconds = String(diffSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const addTimes = (time1, time2) => {
        var time3, time4;
        // Parse the time strings
        (!time1) ? time3 = '00:00:00' : time3 = time1;

        (!time2) ? time4 = '00:00:00' : time4 = time2;

        const [hours1, minutes1, seconds1] = time3.split(':').map(Number);
        const [hours2, minutes2, seconds2] = time4.split(':').map(Number);

        // Add the seconds, minutes, and hours
        let totalSeconds = seconds1 + seconds2;
        let totalMinutes = minutes1 + minutes2 + Math.floor(totalSeconds / 60);
        let totalHours = hours1 + hours2 + Math.floor(totalMinutes / 60);

        // Normalize the result
        totalSeconds = totalSeconds % 60;
        totalMinutes = totalMinutes % 60;
        totalHours = totalHours % 24;

        // Format the result as HH:mm:ss
        const formattedHours = String(totalHours).padStart(2, '0');
        const formattedMinutes = String(totalMinutes).padStart(2, '0');
        const formattedSeconds = String(totalSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const timeToSeconds = (timeStr) => {
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    }

    const compareTimes = (time1, time2) => {
        const seconds1 = timeToSeconds(time1);
        const seconds2 = timeToSeconds(time2);

        if (seconds1 > seconds2) {
            return 1; // time1 is greater than time2
        } else if (seconds1 < seconds2) {
            return 0; // time1 is less than time2
        } else {
            return 0; // time1 is equal to time2
        }
    }

    const handleLogIn = () => {
        const response = axios.put(`${host}/api/employee/${id}`, {
            ...emp,
            attendanceInfo: {
                ...emp.attendanceInfo,
                attendanceRecords: [...emp.attendanceInfo?.attendanceRecords, {
                    date: new Date().toISOString().split('T')[0],
                    inTime: convertTo24Hour(new Date().toLocaleTimeString()),
                    outTime: null,
                    breakStartTime: '',
                    status: '',
                    breakTime: '',
                    totalTime: '',
                    empId:emp.id
                }
                ]
            },
        })
        response.then((result) => {
            if (result.status === 200) {
                setIsClockedIn(true)
                console.log(result.data)
            }
        })
    }

    const handleStartBreak = () => {
        const date = new Date().toLocaleTimeString();
        // console.log(date)
        // console.log({
        //     ...emp,
        //     attendanceInfo: {
        //         ...emp.attendanceInfo,
        //         attendanceRecords: emp.attendanceInfo?.attendanceRecords?.map((obj, index, array) =>
        //             (index === array.length - 1) ? { ...obj, breakStartTime: convertTo24Hour(date) } : obj
        //         ),
        //     },
        // })

        const response = axios.put(`${host}/api/employee/${id}`, {
            ...emp,
            attendanceInfo: {
                ...emp.attendanceInfo,
                attendanceRecords: emp.attendanceInfo?.attendanceRecords?.map((obj, index, array) =>
                    (index === array.length - 1) ? { ...obj, breakStartTime: convertTo24Hour(date) } : obj
                ),
            },
        })
        response.then((result) => {
            if (result.status === 200) {
                setIsOnBreak(true)
                // console.log(result.data)
            }
        })
    }

    const handleBreakEnd = () => {
        const diff = getTimeDifference(attendanceRecords.breakStartTime, convertTo24Hour(new Date().toLocaleTimeString()));
        const totalBreakTime = addTimes(attendanceRecords.breakTime, diff);

        const response = axios.put(`${host}/api/employee/${id}`, {
            ...emp,
            attendanceInfo: {
                ...emp.attendanceInfo,
                attendanceRecords: emp.attendanceInfo?.attendanceRecords?.map((obj, index, array) =>
                    index === array.length - 1
                        ? { ...obj, breakStartTime: '', breakTime: totalBreakTime }
                        : obj
                ),
            },
        })
        response.then((result) => {
            if (result.status === 200) {
                // console.log(result.data)
                setIsOnBreak(false);
            }
        })

    }

    const handleLogOut = () => {
        const clockOutTime = new Date();
        setAttendanceRecords({ ...attendanceRecords, outTime: convertTo24Hour(clockOutTime.toLocaleTimeString()), totalTime: workDuration })
        const response = axios.put(`${host}/api/employee/${id}`, {
            ...emp,
            attendanceInfo: {
                ...emp.attendanceInfo,
                attendanceRecords: emp.attendanceInfo?.attendanceRecords?.map((obj, index, array) =>
                    index === array.length - 1
                        ? { ...obj, outTime: convertTo24Hour(clockOutTime.toLocaleTimeString()), totalTime: workDuration, status: 'present' }
                        : obj
                ),
            },
        })
        response.then((result) => {
            if (result.status === 200) {
                // console.log(result.data)
                setIsClockedIn(false);
                setIsOnBreak(false);
                setShowSummary(true);
            }
        })

        // const summaryData = {
        //     clockInTime: attendanceRecords.inTime,
        //     clockOutTime: clockOutTime,
        //     totalWorkingHours: getTimeDifference(getTimeDifference((clockOutTime - attendanceRecords.inTime)) - attendanceRecords.breakTime),
        //     totalBreakTime: attendanceRecords.breakTime,
        //     date: currentTime.toDateString(),
        // };
    }

    const formatTime = (milliseconds) => {
        const totalMinutes = Math.floor(milliseconds / 1000 / 60);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours} hours and ${minutes} minutes`;
    };

    function convertTo24Hour(timeStr) {
        // console.log(timeStr);
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes, seconds] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM' || modifier==='pm') {
            hours = parseInt(hours, 10) + 12;
        }
        // console.log(`${hours}:${minutes}:${seconds}`);
        return `${hours}:${minutes}:${seconds}`;
    }

    function convertTo12Hour(timeStr) {
        let [hours, minutes, seconds] = timeStr.split(':');
        let modifier = 'AM';

        if (hours >= 12) {
            modifier = 'PM';
            if (hours > 12) {
                hours = hours - 12;
            }
        }

        if (hours == 0) {
            hours = 12;
        }

        return `${hours}:${minutes}:${seconds} ${modifier}`;
    }

    return <div className="container">
        <h2 className="text-start">Hello <em className="text-info">{emp?.personalInfo?.firstName + ' ' + emp?.personalInfo?.lastName}</em></h2>
        <div className="text-center">
            <Clock value={currentTime} className="mx-auto my-3" />
            <h2>{currentTime.toLocaleTimeString()}</h2>
            <h5>{`Date: ${currentTime.toDateString()}`}</h5>
        </div>
        <div className="buttons mx-auto my-5 d-grid">
            <button className={present || isClockedIn ? "btn btn-outline-secondary" : "btn btn-primary"} onClick={handleLogIn} disabled={present || isClockedIn}>
                Log In
            </button>
            <button className={present || (!isClockedIn || isOnBreak || compareTimes(workDuration, '07:00:00')) ? "btn btn-outline-secondary" : "btn btn-warning"} onClick={handleStartBreak} disabled={present || (!isClockedIn || isOnBreak || compareTimes(workDuration, '07:00:00'))}>
                Start Break
            </button>
            <button className={present || (!isOnBreak || !isClockedIn) ? "btn btn-outline-secondary" : "btn btn-success"} onClick={handleBreakEnd} disabled={present || (!isOnBreak || !isClockedIn)}>
                End Break
            </button>
            <button className={present || (!isClockedIn || isOnBreak || !compareTimes(workDuration, '07:00:00')) ? "btn btn-outline-secondary" : "btn btn-danger"} onClick={handleLogOut} disabled={present || (!isClockedIn || isOnBreak || !compareTimes(workDuration, '07:00:00'))}>
                Log Out
            </button>
        </div>
        <div className="text-start">
            <h3>Current Status</h3>
            {present && <h4 className="text-center">You have successfully Marked Today</h4>}
            {attendanceRecords.inTime && <p>Logged-in Time : <b>{attendanceRecords.inTime==="00:00:00"?'':convertTo12Hour(attendanceRecords.inTime)}</b></p>}
            {!present && attendanceRecords.breakStartTime && isOnBreak && <p>{`Break Start Time: ${convertTo12Hour(attendanceRecords.breakStartTime)}`}</p>}
            {!present && !isOnBreak && attendanceRecords.breakStartTime && !attendanceRecords.outTime && <p>{`Break End Time: ${addTimes(attendanceRecords.breakTime, attendanceRecords.breakStartTime)}`}</p>}
            {attendanceRecords.outTime && <p>Logged-out Time : <b>{attendanceRecords.outTime ==="00:00:00"?'':convertTo12Hour(attendanceRecords.outTime)}</b></p>}
        </div>
        <Modal show={showSummary} onHide={() => setShowSummary(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Thank you for your hard work today.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Today's Attendance Information</h4>
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td>Clock-in Time:</td>
                            {attendanceRecords.inTime && <td>{convertTo12Hour(attendanceRecords.inTime)}</td>}
                        </tr>
                        <tr>
                            <td>Clock-out Time:</td>
                            {attendanceRecords.outTime && <td>{convertTo12Hour(attendanceRecords.outTime)}</td>}
                        </tr>
                        <tr>
                            <td>Total Working Hours:</td>
                            <td>{attendanceRecords.totalTime}</td>
                        </tr>
                        <tr>
                            <td>Total Break Time:</td>
                            <td>{attendanceRecords.breakTime}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td>{currentTime.toDateString()}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={() => { setShowSummary(false) }}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    </div>

    // return <div>
    //     {convertTo24Hour("01:00:00 PM")}
    // </div>
}
export default MarkAttendance;
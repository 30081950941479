import axios from "axios";
import React, { useState, useEffect } from "react";
import { CSVLink } from 'react-csv';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import "../App.css";
import ExcelJS from 'exceljs';

const AttendanceList = ({ id, host }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [attendanceData, setAttendanceData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date).toLocaleDateString('en-GB', options);  // Format to DD/MM/YYYY
  };

  useEffect(() => {
    fetchAttendanceData(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  const fetchAttendanceData = async (month, year) => {
    // Replace with your backend API endpoint
    const response = await axios.get(`${host}/api/employeeAttendance/${id}?month=${month}&year=${year}`);
    const sortedData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
  setAttendanceData(sortedData);
    const filteredData = response.data.map(employee => {
      const { breakStartTime, empId, ...rest } = employee;  // Exclude dateOfBirth
      return { ...rest, date: formatDate(employee.date) };
    });
    setCsvData(filteredData);
  };

  const handlePreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  function convertTo12Hour(timeStr) {
    let [hours, minutes, seconds] = timeStr.split(':');
    let modifier = 'AM';

    if (hours >= 12) {
      modifier = 'PM';
      if (hours > 12) {
        hours = hours - 12;
      }
    }

    if (hours == 0) {
      hours = 12;
    }

    return `${hours}:${minutes}:${seconds} ${modifier}`;
  }

  const generateExcel = async (data) => {

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Attendance');

    // Define header row
    const headers = ['S.No', 'Date', 'Log In Time', 'Break Time', 'Log Out Time', 'Total Time', 'Status'];

    // Add header row
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((row, index) => {
      worksheet.addRow([
        index + 1,
        row.date,
        row.inTime,
        row.breakTime,
        row.outTime,
        row.totalTime,
        row.status
      ]);
    });

    // Apply styles to headers
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center' };
    });

    // Apply center alignment to all cells
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.alignment = { horizontal: 'center' };
      });
    });

    // Auto-size columns based on content
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const valueLength = cell.value ? cell.value.toString().length : 0;
        if (valueLength > maxLength) {
          maxLength = valueLength;
        }
      });
      column.width = maxLength + 2; // Add padding
    });

    // Generate Excel file and trigger download
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Attendance.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  }

  const downloadPayroll = async () => {
    try {
      const response = await axios.get(`${host}/api/payrollemployee/${id}`)
      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Payroll');

      // Define header row
      const headers = ['S.No', 'Emp Id', 'Name', 'Month', 'Working Days', 'Salary', 'Days Present', 'Net Salary'];

      // Add header row
      worksheet.addRow(headers);

      // Add data rows
      response.data.forEach((row, index) => {
        worksheet.addRow([
          index + 1,
          row.empId,
          row.name,
          row.monthYear,
          row.workingDays,
          row.totalSalary,
          row.daysPresent,
          row.netSalary
        ]);
      });

      // Apply styles to headers
      worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center' };
      });

      // Apply center alignment to all cells
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          cell.alignment = { horizontal: 'center' };
        });
      });

      // Auto-size columns based on content
      worksheet.columns.forEach(column => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, cell => {
          const valueLength = cell.value ? cell.value.toString().length : 0;
          if (valueLength > maxLength) {
            maxLength = valueLength;
          }
        });
        column.width = maxLength + 2; // Add padding
      });

      // Generate Excel file and trigger download
      workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Payroll.xlsx';
        a.click();
        URL.revokeObjectURL(url);
      });
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="App m-4">
      <div className="export">
        <h4 className="text-start">Attendance Sheet</h4>
        <div className="csv">
          {attendanceData.length > 0 && (
            // <CSVLink data={csvData} headers={headers} filename={"attendance.csv"}>
            <button className="button btn btn-outline-info mx-2" onClick={() => generateExcel(csvData)}>CSV Export</button>
            // </CSVLink>
          )}
          {/* <button className="button btn btn-dark mx-2 py-3" onClick={()=>axios.get(`${host}/api/generatepayroll/August 2024`)}>Generate</button> */}
          {/* <button className="button btn btn-light mx-2 py-3" onClick={() => downloadPayroll()}>Payroll Information</button> */}
        </div>
      </div>
      <div className="header d-flex fs-5">
        <button className="btn" onClick={handlePreviousMonth}><FaChevronLeft /></button>
        <div className="my-auto">{`${currentMonth} / ${currentYear}`}</div>
        <button className="btn" onClick={handleNextMonth}><FaChevronRight /></button>
      </div>
      <div>
        <table className="table border">
          <thead>
            <tr>
              <th>Date</th>
              <th>Log In</th>
              <th>Log Out</th>
              <th>Break </th>
              <th>Working Hours</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData && attendanceData.map((entry, index) => (
              <tr>
                <td>{entry.date}</td>
                <td>{entry.inTime}</td>
                <td>{entry.outTime}</td>
                <td>{entry.breakTime}</td>
                <td>{entry.totalTime}</td>
                <td>{entry.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AttendanceList;
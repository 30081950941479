import React, { useEffect } from 'react';

const SessionTimeOut = ({setIsLoggedIn, children }) => {

  const logoutUser = () => {
    // Clear the JWT token from localStorage or any other storage
    localStorage.removeItem('token');
    // Clear the login timestamp
    localStorage.removeItem('loginTimestamp');
    localStorage.removeItem('id');
    localStorage.removeItem('level');
    setIsLoggedIn(false)
    window.location.href='/'
  };

  useEffect(() => {
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    if (loginTimestamp) {
      const timeElapsed = Date.now() - parseInt(loginTimestamp, 10);
      const timeout = 180 * 60 * 1000 - timeElapsed;

      if (timeout <= 0) {
        logoutUser();
      } else {
        setTimeout(logoutUser, timeout);
      }
    }
  }, []);

  return <>{children}</>;
};

export default SessionTimeOut;

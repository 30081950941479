import React, { useEffect, useState } from "react";
import vinnaglogo from "../images/vinnaglogo1.png";
import { useHistory } from "react-router-dom";
import { MdOutlineLogout } from "react-icons/md";
import "../App.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";

const AttendanceSidebar = ({ setId, handleLogout }) => {
  const history = useHistory();

  const [active,setActive]=useState('stamp');

  return <><div className="sidebar border bg-dark pe-3" >
    <div className="navlist">
      <div>
        <li className="d-flex mx-1"><img src={vinnaglogo} className=" mt-4 mx-auto bg-white my-2" alt="Profile Image" style={{ borderRadius: '50%' }} height={70} width={70} />
        {/* <h4 className='text-white my-auto vinnag'>Vinnag HRMS</h4> */}
        </li>
        <li className="attendancemanagement"><h3 className="text-white text-center" style={{ maxWidth: '230px' }}>Attendance Management</h3></li>
      </div><br/>
      <nav>
        <div className="ps-4 navlist">
          <button className={active === 'stamp'?"btn btn-info p-2 w-100 text-start my-4 nav-link active":"p-2 text-start text-white my-4 nav-link active"} onClick={() => {setActive('stamp');history.push("/")}}>Stamp</button>
          <button className={active ==='list'?"btn btn-info p-2 w-100 text-start my-4 nav-link active":"p-2 text-start text-white my-4 nav-link active"} onClick={() =>{setActive('list'); history.push("/list")}}>Attendance-List</button>
        </div>
      </nav>
    </div>
    <div className="ps-4 mb-4">
      <nav>
        <ul>
          {/* <li className="d-flex text-secondary my-4" onClick={()=>history.push("/hrsettings")}><a className="d-flex mx-3 my-0 py-0">   Settings</a></li> */}
          <li className="d-flex text-white my-4" onClick={() => handleLogout()}><MdOutlineLogout className="logoutlogo fs-3" /><a className="text-white d-flex mx-3 my-0 py-0">   LOG OUT</a></li>
        </ul>
      </nav>
    </div>
  </div>

  <nav className="navbar navbar-expand-lg navbar-light bg-dark hide-on-large-screens">
      <div className="container-fluid">
        <a className="text-white navbar-brand d-flex">
          <img src={vinnaglogo} alt="Logo"  width="50" height="50" className="text-center bg-white d-inline-block mx-2" style={{ borderRadius: '50%' }}/>
          <h3 className="my-auto">Attendance Management</h3>
        </a>
        <button
          className="navbar-toggler bg-white border mx-3 px-2" style={{ borderRadius: '5px' }} type="button"
          data-bs-toggle="collapse" data-bs-target="#navbarNav"  aria-controls="navbarNav"  aria-expanded="false" aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse bg-secondary" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item" style={{cursor: 'pointer'}}>
              <a className={active === 'stamp'?"bg-info nav-link active":"text-white nav-link active"} aria-current="page" onClick={() =>{setActive('stamp'); history.push('/')}}> Stamp </a>
            </li>
            <li className="nav-item" style={{cursor: 'pointer'}}>
              <a className={active === 'list'?"bg-info nav-link active":"text-white nav-link"} onClick={() =>{setActive('list'); history.push('/list')}} > Attendance List </a>
            </li>
            <li className="nav-item">
              <a  className="text-white nav-link" onClick={handleLogout} style={{cursor: 'pointer'}}>  Logout  </a>
            </li>
          </ul>
        </div>
      </div>
    </nav></>
}
export default AttendanceSidebar;
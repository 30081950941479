import React, { useState,useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import forgotimg from "../images/forgotimg.svg";

const ResetPassword = ({ host, email }) => {
  const history = useHistory();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async () => {
    if (newPassword && confirmPassword && newPassword === confirmPassword) {
      try {
        const response = await axios.post(`${host}/api/reset-password`, null, {
          params: { email, newPassword }
        });
        setMessage(response.data);

        if (response.data === "Password reset successfully!") {
          setTimeout(() => {
            history.push('/');
          }, 3000); // Redirect after 3 seconds
        }
      } catch (error) {
        setMessage('Failed to reset password. Please try again.');
      }
    } else {
      setMessage("Passwords don't match. Please verify.");
    }
  };


  useEffect(() => {
    // Redirect to starting page if the email is not provided
    if (!email) {
      history.push('/');
    }

    // Prevent navigation back
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        return 'The page that you\'re looking for used information that you entered. Returning to that page might cause any action you took to be repeated. Do you want to continue?';
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [email, history]);
  return (
  <div className="container-fluid" style={{ backgroundColor: 'rgb(94, 130, 244)' }}>
  <div className="row">
    <div className="col-md-6 d-flex justify-content-center align-items-center py-5 bg-white" style={{ borderRadius: '0 20px 20px 0' }}>
      <div className="border rounded shadow p-5 loginform mx-auto email">
        <h2 className="mb-3 "><b>Reset Your Password</b></h2>
        <label className="form-label w-100 my-3 text-start">
          New Password
          <div className="input-group mb-3" style={{ position: 'relative' }}>
            <input value={newPassword} className="form-control no-browser-eye mt-2"onChange={(e) => setNewPassword(e.target.value)}type={showNewPassword ? "text" : "password"}placeholder="Enter New Password"style={{ paddingRight: '40px' }}/>
            {newPassword && (
              <span className="input-group-text"
                style={{cursor: 'pointer',position: 'absolute',right: '10px',top: '65%',transform: 'translateY(-65%)',zIndex: '10',backgroundColor: 'transparent',border: 'none',}}
                onClick={() => setShowNewPassword(!showNewPassword)}>{showNewPassword ? <FaRegEyeSlash /> : <FaEye />}</span>
            )}
          </div>
        </label>
        <label className="form-label w-100 my-3 text-start">
          Confirm Password
          <div className="input-group mb-3" style={{ position: 'relative' }}>
            <input value={confirmPassword} className="form-control no-browser-eye mt-2" onChange={(e) => setConfirmPassword(e.target.value)}
              type={showConfirmPassword ? "text" : "password"} placeholder="Re-enter Password" style={{ paddingRight: '40px' }}/>
            {confirmPassword && (
              <span className="input-group-text"
            style={{ cursor: 'pointer',position: 'absolute',right: '10px',top: '65%',transform: 'translateY(-65%)',zIndex: '10',backgroundColor: 'transparent',border: 'none',}}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? <FaRegEyeSlash /> : <FaEye />}</span>
            )}
          </div>
            </label>
            <button className="btn btn-success" onClick={handleResetPassword}>
              Reset Password
            </button>
            <p>{message}</p>
          </div>
        </div>
        <div className="col-md-6 forgotimg loginimg">
          <img src={forgotimg} alt="reset password" width='100%' height='100%' />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import axios from "axios";
import loginimg from "../images/loginimg.svg";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import {useHistory} from "react-router-dom";
import { FaEye } from "react-icons/fa";  
import { FaRegEyeSlash } from "react-icons/fa";


const SignUp = ({id,host,email,setEmail}) => {
const history = useHistory();

    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    
    const [showPassword,setShowPassword]=useState(false)

    const [employee, setEmployee] = useState({
        employeeId: '',
        level:'admin',
        personalInfo: {
            firstName: '',
            lastName: '',
            middleName: '',
            gender: '',
            dateOfBirth: '',
            nationality: '',
            maritalStatus: '',
            profilePicture: '',
            password: ''
        },
        contactInfo: {
            email: '',
            phone: '',
            address: '',
            name: '',
            relationship: '',
            emergencyPhone: '',
            // emergencyContact: {
            //     name: '',
            //     relationship: '',
            //     emergencyPhone: ''
            // }
        },
        jobInfo: {
            jobTitle: '',
            department: '',
            manager: '',
            employmentType: '',
            employmentStatus: '',
            dateOfHire: '',
            dateOfTermination: '',
            workLocation: ''
        },
        compensationInfo: {
            salary: 0,
            bonuses: [{ year: '', amount: '' }],
            benefits: {
                healthInsurance: '',
                retirementPlan: ''
            },
            payrollInfo: [{
                name:'',
                daysPresent:'',
                workingDays:'',
                totalSalary:'',
                netSalary:'',
                monthYear:'',
                bankAccount: '',
                taxId: '',
                empId:''
            }]
        },
        attendanceInfo: {
            attendanceRecords: [{ date: '', inTime: null, outTime: null, breakStartTime: '', breakTime: null, totalTime: null, status: '' }],
            leaveRecords: [{ type: '', from: '', to: '', status: '' }]
        },
        performanceInfo: {
            performanceReviews: [{ date: '', score: '', feedback: '' }],
            goals: [{ goal: '', progress: '' }],
            trainingPrograms: [{ name: '', date: '', certification: '' }]
        },
        documents: {
            idProof: '',
            workAuthorization: '',
            signedContracts: '',
            complianceRecords: ''
        },
        notes: '',
        feedback: [{ empName: '', message: '' }]
    });

    const [message, setMessage] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false)

    const handleSubmit = async () => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
            // Validation logic
            if (!name) {
                alert('Please enter your name.');
                return;
            }
            if (!email) {
                alert('Please enter your email.');
                return;
            }
            if (!emailRegex.test(email) || !email.endsWith('@gmail.com')) {
                alert('Please enter a valid Gmail address.');
                return;
            }
            if (!password) {
                alert('Please enter your password.');
                return;
            }
            if (!agreeTerms) {
                alert("Please accept the Terms & Conditions.");
                return;
            }
    
            const response = await axios.post(`${host}/api/employee`, { 
                ...employee, 
                personalInfo: { ...employee.personalInfo, firstName: name, password: password }, 
                contactInfo: { ...employee.contactInfo, email: email },
                compensationInfo: { ...employee.compensationInfo, name: name }
            });
    
            if (response.status === 200) {
                alert('Signup successful! Please log in.');
                history.push('/');
            } else {
                alert('Signup failed. Please try again.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 409) {
                    alert('User already registered.');
                } else {
                    alert(`Error: ${error.response.data}`);
                }
            } else {
                alert('An error occurred while processing your request.');
            }
        }
    };
    

    return (
        <div className="container-fluid "style={{ backgroundColor: 'rgb(94, 130, 244)'}}>
            <div className="row">
                <div className="col-md-6 -5 bg-white" style={{ borderRadius: '0 20px 20px 0' }}>
                    <div className=" border rounded shadow p-5 loginform mx-auto">
                        <h2 className="mb-3"><b>Welcome to our Vinnag Attendance Management.
                            Sign In to see latest updates.</b></h2>
                        <p>Enter your details to proceed further</p>
                        <br />
                        <label className="form-label w-100 my-3 text-start">Name <input value={name} className="form-control mt-2" onChange={(e) => setName(e.target.value)} type="text" placeholder="Enter your Name" required/></label>
                        <label className="form-label w-100 my-3 text-start">Email <input value={email} className="form-control mt-2" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter Email" required/></label><br />
                        <label className="form-label w-100 my-3 text-start">Set Password
                            <div className="input-group mb-3" style={{ position: 'relative' }}>
                                <input value={password} className="form-control no-browser-eye mt-2" onChange={(e) => setPassword(e.target.value)} 
                                    type={showPassword ? "text" : "password"} placeholder="Enter Password" required style={{ paddingRight: '40px' }} // Add space for the icon
                                />
                                {password && (
                                    <span className="input-group-text" style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '65%', transform: 'translateY(-65%)', zIndex: '10', backgroundColor: 'transparent', border: 'none' 
                                        }}
                                        onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <FaRegEyeSlash />
                                        ) : (
                                            <FaEye />
                                        )}
                                    </span>
                                )}
                            </div>
                        </label>
                        <label><input className="form-check-input" type="checkbox" value={agreeTerms} onClick={() => setAgreeTerms(!agreeTerms)} /> I agree to all Terms & Conditions</label><br/>
                        Already Registered..? <button className="col btn btn-link " style={{ textDecoration: 'none',color: 'rgb(94, 130, 244)', padding: 0, fontSize: 'inherit',fontWeight: 'bold', cursor: 'pointer' }} 
                        onClick={() => history.push('/')}>SIGN IN</button>
                        <div>
                            <button className="btn px-3 btn-primary mt-4" onClick={() => handleSubmit()}>SIGN UP</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 loginimg">
                    <img src={loginimg} alt="image" width='100%' height='100%' />
                </div>
            </div>
        </div>
    )
}
export default SignUp;
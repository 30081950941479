import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const TimeOutLogOut = ({setIsLoggedIn, children }) => {
  let timeout;

  const logoutUser = () => {
    // Clear the JWT token from localStorage or any other storage
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('loginTimestamp');
    localStorage.removeItem('level');
    setIsLoggedIn(false)
    window.location.href='/'
  };

  const resetTimeout = () => {
    // Clear the existing timeout
    if (timeout) clearTimeout(timeout);

    // Set a new timeout
    timeout = setTimeout(logoutUser, 15 * 60 * 1000); // 15 minutes
  };

  const setupTimeout = () => {
    // Reset the timeout on user activity
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keypress', resetTimeout);
  };

  useEffect(() => {
    setupTimeout();
    resetTimeout(); // Initialize the timeout

    // Cleanup on component unmount
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keypress', resetTimeout);
    };
  }, []);

  return <>{children}</>;
};

export default TimeOutLogOut;

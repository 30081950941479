import React, { useEffect, useState } from "react";
import axios from "axios";
import loginimg from "../images/loginimg.svg";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import {useHistory} from "react-router-dom";
import { FaEye } from "react-icons/fa";  
import { FaRegEyeSlash } from "react-icons/fa";  


const LoginPage = ({ setId, host, setIsLoggedIn,email,setEmail }) => {
    const urlParams = new URLSearchParams(window.location.search);
  const inactive = urlParams.get('inactive');
  if (inactive === 'true') {
    alert('You have been logged out due to inactivity.');
  }
    const history=useHistory();

    const [showPassword,setShowPassword]=useState(false)

    const [password, setPassword] = useState('');

    const handleSubmit = async () => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            
            // Validation logic
            if (!email) {
                alert('Please enter your email.');
                return;
            }
            if (!emailRegex.test(email) || !email.endsWith('@gmail.com')) {
                alert('Please enter a valid Gmail address.');
                return;
            }
            if (!password) {
                alert('Please enter your password.');
                return;
            }
    
            let response;
            if (email && password) {
                response = await axios.get(`${host}/api/login/${email}/${password}`);
    
                // Save the token if login is successful
                if (response.status === 200) {
                    const id = response.data.id;
                    const token = response.data.token;
                    const level = response.data.level;
                    if (id != null && token != null) {
                        localStorage.setItem('id', id);
                        localStorage.setItem('token', token);
                        localStorage.setItem('level', level);
                        localStorage.setItem('loginTimestamp', Date.now());
                        setId(parseInt(response.data.id));
                        setIsLoggedIn(true);
                        
                    } else {
                        alert("User Not Found");
                    }
                } else {
                    alert('Login failed. Please check your credentials.');
                }
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 404) {
                    alert('Email not registered. Please sign up.');
                } else if (error.response.status === 401) {
                    alert('Incorrect password. Please try again.');
                } else {
                    alert(`Error: ${error.response.data}`);
                }
            } else {
                alert('An error occurred while processing your request.');
            }
        }
    };
    

    return (<div className="container-fluid "style={{ backgroundColor: 'rgb(94, 130, 244)' }}>
        <div className="row">
            <div className="col-md-6 bg-white" style={{ borderRadius: '0 20px 20px 0' }}>
                <div className=" border rounded shadow p-5 loginform mx-auto">
                    <h2 className="mb-3"><b>Welcome to our Vinnag Attendance Management.
                        Sign In to see latest updates.</b></h2>
                    <p >Enter your details to proceed further</p>
                    <br />
                        <label className="form-label w-100 my-3 text-start">Email <input value={email} className="form-control mt-2" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Enter Email" autoComplete="on" style={{ paddingRight: '40px' }}/>
                        </label><br />
                            <label className="form-label w-100 my-3 text-start" >Password
                            <div className="input-group mb-3" style={{ position: 'relative' }}>
            <input value={password} className="form-control no-browser-eye mt-2"onChange={(e) => setPassword(e.target.value)}type={showPassword ? "text" : "password"}placeholder="Enter Password"
                style={{ paddingRight: '40px' }} // Add some padding to the right to make space for the icon
            />
            {password && (
                <span className="input-group-text d-flex " style={{cursor: 'pointer',position: 'absolute',right: '10px',top: '65%',transform: 'translateY(-65%)',zIndex: '10',backgroundColor: 'transparent',border: 'none',
                    }}
                >
                    {showPassword ? (
                        <FaRegEyeSlash onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                        <FaEye onClick={() => setShowPassword(!showPassword)} />
                    )}
                </span>
            )}
        </div>
        </label>
                        <div className="row d-flex mt-3" style={{ marginBottom: '40px' }}>
                            
                        <label className=" col text-start "><input type="radio" className="form-check-input" /> Remember me</label>
                        <button className="col btn btn-link " style={{ textDecoration: 'none',color: 'rgb(94, 130, 244)', padding: 0, fontSize: 'inherit',fontWeight: 'bold', cursor: 'pointer' }} 
                        onClick={() => history.push('/enter-email')}>Recover password</button>
                        </div>
                        <div className="d-grid my-4" style={{ gridTemplateColumns: 'auto auto', gridTemplateRows: '50px 50px', gap: '15px 20px' ,marginBottom: '20px'}}>
                            <button className="btn px-3 btn-primary" onClick={() => handleSubmit()}>SIGN IN</button>
                            <button className="btn px-3 btn-light custom-hover" onClick={() => {history.push('/sign_up')}}>SIGN UP</button>
                        </div>
                </div>
            </div>
            <div className="col-md-6 loginimg">
                <img src={loginimg} alt="image" width='100%' height='100%' />
            </div>
        </div>
    </div>)
}
export default LoginPage;
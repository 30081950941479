import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import forgotimg from "../images/forgotimg.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

const EnterEmail = ({ host, email, setEmail }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSendCode = async () => {
    if (email) {
      setLoading(true); // Start spinner
      try {
        const response = await axios.post(`${host}/api/send-code`, null, {
          params: { email }
        });
        setMessage(response.data);
        setLoading(false); // Stop spinner

        if (response.data.includes("Verification code sent")) {
          history.push('/enter-otp');
        } else if (response.data.includes("Email not registered")) {
          setMessage('Email not registered. Please sign up.');
        }
      } catch (error) {
        setLoading(false); // Stop spinner
        setMessage('Failed to send code. Please try again.');
      }
    } else {
      setMessage('Please enter a valid email.');
    }
  };

  return (
    <div className="container-fluid" style={{ backgroundColor: 'rgb(94, 130, 244)' }}>
      <div className="row justify ">
        <div className="col-md-6 d-flex justify-content-center align-items-center py-5 bg-white " style={{ borderRadius: '0 20px 20px 0' }}> 
          <div className="border rounded shadow p-5 loginform mx-auto email">
            <h2 className="mb-3"><b>Lost your password? <br />
              Enter your email to recover</b></h2>
            <p>Enter your email to proceed further</p>
            <label className="form-label w-100 my-3">
              Email
              <input 
                value={email} 
                className="form-control" 
                onChange={(e) => setEmail(e.target.value)} 
                type="email" 
                placeholder="Enter Email" 
              />
            </label>
            <button className="btn btn-primary" onClick={handleSendCode} disabled={loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending...
                </>
              ) : (
                'Send Code'
              )}
            </button>
            <p>{message}</p>
          </div>
        </div>
        <div className="col-md-6 forgotimg loginimg">
          <img src={forgotimg} alt="forgot password" width='100%' height='100%' />
        </div>
      </div>
    </div>
  );
};

export default EnterEmail;

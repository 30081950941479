import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import forgotimg from "../images/forgotimg.svg";

const EnterOTP = ({ host, email }) => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(45); // Set the timer to 45 seconds
  const [resendDisabled, setResendDisabled] = useState(true); // Initially disable the resend button
  const history = useHistory();
  useEffect(() => {
    // Redirect to starting page if the email is not provided
    if (!email) {
      history.push('/');
    }

    // Prevent navigation back
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        return 'The page that you\'re looking for used information that you entered. Returning to that page might cause any action you took to be repeated. Do you want to continue?';
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [email, history]);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setResendDisabled(false); // Enable the resend button when timer hits zero
    }
  }, [timer]);

  const handleVerifyCode = async () => {
    try {
      const response = await axios.post(`${host}/api/verify-code`, null, {
        params: { email, code, newPassword: "newPasswordValue" }
      });
  
      setMessage(response.data);
      if (response.data === "Code verified successfully!") {
        history.push('/reset-password');
      }
    } catch (error) {
      if (error.response.status === 401) {
        setMessage("Invalid verification code. Please try again.");
      } else if (error.response.status === 404) {
        setMessage("Invalid Email");
      } else {
        setMessage("Failed to verify code. Please try again.");
      }
    }
  };
  

  const handleResendCode = async () => {
    try {
      await axios.post(`${host}/api/send-code`, null, {
        params: { email }
      });
      setMessage('Verification code resent.');
      setTimer(45); // Reset the timer to 45 seconds
      setResendDisabled(true); // Disable the resend button again
    } catch (error) {
      setMessage('Failed to resend code. Please try again.');
    }
  };

  return (
    <div className="container-fluid" style={{ backgroundColor: 'rgb(94, 130, 244)' }}>
      <div className="row">
      <div className="col-md-6 d-flex justify-content-center align-items-center py-5 bg-white" style={{ borderRadius: '0 20px 20px 0' }}>
      <div className="border rounded shadow p-5 loginform mx-auto email">
            <h2 className="mb-3"><b>Enter the OTP sent to your email</b></h2>
            <label className="form-label w-100 my-3">
              OTP
              <input value={code} className="form-control" onChange={(e) => setCode(e.target.value)} type="text" placeholder="Enter OTP" />
            </label>
            <button className="btn btn-primary" onClick={handleVerifyCode}>Verify Code</button>
            <p>{message}</p>
            <button className="btn btn-secondary mt-3" onClick={handleResendCode} disabled={resendDisabled}>
              Resend OTP {resendDisabled && `in ${timer} sec`}
            </button>
          </div>
        </div>
        <div className="col-md-6 forgotimg loginimg">
          <img src={forgotimg} alt="enter otp" width='100%' height='100%' />
        </div>
      </div>
    </div>
  );
};

export default EnterOTP;

import logo from './logo.svg';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import './App.css';
import axios from 'axios';

import LoginPage from './components/login';
import SignUp from './components/signUp';
import AttendanceSidebar from './components/sidebar';
import AttendanceList from './components/attendanceList';
import MarkAttendance from './components/attendance';

import SessionTimeOut from './components/sessionTimeOut';
import TimeOutLogOut from './components/timeOutLogOut';
import EnterEmail from './components/EnterEmail';
import EnterOTP from './components/EnterOTP';
import ResetPassword from './components/ResetPassword';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const history = useHistory();

  const [email, setEmail] = useState('');

  const host = "https://invigorating-dream-production.up.railway.app";

  // const host = "http://localhost:8080";

  //  const host = "https://invigorating-dream-production.up.railway.app";

  const [id, setId] = useState();
  const [level, setLevel] = useState();

  const [empId, setEmpId] = useState();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setId(parseInt(localStorage.getItem('id'), 10));
      setLevel(localStorage.getItem('level'));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    // Remove JWT from localStorage or sessionStorage
    localStorage.removeItem('token');  // Or sessionStorage.removeItem('token');
    localStorage.removeItem('id');  // Or sessionStorage.removeItem('id');
    localStorage.removeItem('loginTimestamp');  // Or sessionStorage.removeItem('loginTimestamp');
    localStorage.removeItem('level');

    // Optionally, redirect to the login page
    window.location.href = '/';
    setIsLoggedIn(false);
    setId('');
    // history.push('/')
  };

  return (
    <div className="App">
      <Router>
        {isLoggedIn ?
          <div className='appview'>
            <SessionTimeOut setIsLoggedIn={setIsLoggedIn}>
              <TimeOutLogOut setIsLoggedIn={setIsLoggedIn}>
                <div>
                  <AttendanceSidebar setId={setId} handleLogout={handleLogout} />
                  </div>
                <div className='mt-5 w-100'>

                    <Switch>

                      <Route path='/' exact render={() => <MarkAttendance id={id} host={host} setEmpId={setEmpId} />} />
                      <Route path='/list' render={() => <AttendanceList id={id} host={host} empId={empId} />} />
                    </Switch>
                </div>
              </TimeOutLogOut>
            </SessionTimeOut>
          </div>
          :
          <Switch>
            <Route path='/' exact render={() => <LoginPage host={host} setId={setId} email={email} setEmail={setEmail} setIsLoggedIn={setIsLoggedIn} />} />
            <Route path='/sign_up' render={() => <SignUp id={id} host={host} email={email} setEmail={setEmail} />} />
            <Route path='/enter-email' render={() => <EnterEmail host={host} email={email} setEmail={setEmail} />} />
            <Route path='/enter-otp' render={() => <EnterOTP host={host} email={email} />} />
            <Route path='/reset-password' render={() => <ResetPassword host={host} email={email} />} />
          </Switch>
        }
      </Router>

    </div>
  );
}


export default App;
